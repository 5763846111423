body::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #90caf9;
  /* outline: 1px solid slategrey; */
}

body {
  min-width: 400px;
}